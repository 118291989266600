<template>
    <el-container>
      <el-main>
         <el-row type="flex" justify="center">
            <el-image :src="require('@/assets/logo.svg')" class="logo"></el-image>
         </el-row>
         <el-row :gutter="12" type="flex" justify="center">
            <el-col :span="6" class="form">
               <el-card class="box-card">
                  <template #header>
                     <div class="card-header">
                        <span>Восстановление пароля</span>
                     </div>
                  </template>
                  <el-form :model="forgotForm" :rules="rules" ref="forgotForm">
                        <el-form-item prop="email">
                              <el-input
                                 type="text"
                                 v-model.trim="forgotForm.email"
                                 placeholder="Введите email указанный при регистрации"
                              ></el-input>
                        </el-form-item>
                        <el-form-item>
                              <el-button @click="onForgot()">Отправить</el-button>
                        </el-form-item>
                        <el-link>
                           <router-link :underline="false" to="/login">Вернуться к логину</router-link>
                        </el-link>
                  </el-form>
               </el-card>
            </el-col>
         </el-row>
      </el-main>
   </el-container>
</template>
<script>

// import axiosInstance from '../services/AxiosTokenInstance';
import { ElMessage } from 'element-plus';
import { mapActions, mapMutations } from 'vuex';
// import ForgotValidations from '../services/ForgotValidation';
import {
    LOADING_SPINNER_SHOW_MUTATION,
    FORGOT_ACTION,
} from '../store/storeconstants';
export default {
    data() {
        return {
            errors: [],
            error: '',
            forgotForm: {
               email: '',
            },
            rules: {
               email: [
                  { required: true, message: 'Поле email не может быть пустым', trigger: 'blur' },
                  { type: 'email', message: 'Email адрес не корректный', trigger: ['blur', 'change'] }
               ]
            }
        };
    },
    methods: {
        ...mapActions('auth', {
            forgot: FORGOT_ACTION,
        }),
        ...mapMutations({
            showLoading: LOADING_SPINNER_SHOW_MUTATION,
        }),
        async onForgot() {
            try {
               await this.forgot({
                    email: this.forgotForm.email
               })

               await ElMessage({
                  type: "success",
                  message: "Вам на почту выслана ссылка",
               })

               await this.$router.push('/login');

            } catch (error) {
               //  this.showLoading(false);
               this.error = error;
                  ElMessage({
                     type: 'warning',
                     message: `${this.error}`,
                  });
            }
            // this.showLoading(false);
            // this.$router.push('/login');
        },
    },
};
</script>

<style>
   .logo {
      margin: 15px 0px 35px;
   }
</style>

<style scoped>
   .el-container {
         background: #5E597B;
         height: 100%;
      }
   .el-button {
      width: 100%;
   }
   a {
      text-decoration: none;
   }
   .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
</style>
